const companyNameMapper = {
  aim: 'AI Momentum',
  aia: 'AIA',
  nwlife: 'New World Life',
  gta: 'SwimRun Association',
  swire: 'Swire Properties',
  sunlife: 'Sun Life',
  shk: 'Sun Hung Kai Properties',
  clp: 'China Light and Power Company',
  eduhk: 'The Education University of Hong Kong',
};

const agentMode = {
  NormalAdvisor: 'NormalAdvisor',
  SupportService: 'SupportService',
  SalesAdvisor: 'SalesAdvisor',
};
const menuType = {
  Chatroom: 'Chatroom',
  Compare: 'Compare',
  Upload: 'Upload',
};

const chatbotMode = {
  Idle: 'Idle',
  Streaming: 'Streaming',
};

const toolCallType = {
  GetCurrentAgentProfile: 'get_current_agent_profile',
  GetLeads: 'get_leads',
  SearchFromKnowledgeBase: 'search_from_knowledge_base',
  GetAvailableProducts: 'get_available_products',
  ToInsuranceProductResearcher: 'to_insurance_product_researcher',
  ToAgentCopilot: 'to_agent_copilot',
};

export { companyNameMapper, agentMode, menuType, toolCallType, chatbotMode };
