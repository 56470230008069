import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '#components/portal/portal.scss';

import livechatBackground from '../assets/livechat-background.jpg';

// Components
import { PortalBanner, PortalBody, PortalFooter } from '#components/portal';
import { FloatingChatbot } from "#components/floatingChatbot";
// Hooks
import { useTheme, useChatbot } from '#hooks';

const Portal = () => {
  useTheme();
  const { agentCode } = useParams();
  const { chatbot, subscribeNotification, initializeChatbot } = useChatbot();
  const { isLoaded } = chatbot;


  useLayoutEffect(() => {
    if (chatbot.isLoaded) return;
    initializeChatbot();
  }, [initializeChatbot, chatbot.isLoaded]);

  useLayoutEffect(() => {
    const abortController = new AbortController();
    if (agentCode)
      subscribeNotification({ agentCode, signal: abortController.signal });

    return () => {
      abortController.abort();
    };
  }, [subscribeNotification, agentCode]);

  return (
    <div className='portal'>
      <img src={livechatBackground} />
      {
        isLoaded && <FloatingChatbot />
      }
      {/* <PortalBanner />
      <PortalBody />
      <PortalFooter /> */}
    </div>

  );
};

export default Portal;